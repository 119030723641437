<template>
  <div class="ruralFilm">
    <!--    <Header :show-search="false"  @search="updateGroupList"/>-->
    <nav-bar :title="''" />
    <!-- 模块标题 -->
    <div class="module-title">
      <div class="module-title-text">精选推荐</div>
      <div class="module-title-more"  style="color: #007aff; padding: 0 5px" @click="goPage('/home')">返回首页</div>
    </div>
    <Swipe :bannerList="themeList" imgKey="poster" @onTap="tapBanner" show-text />
    <div class="module-title">
      <div class="module-title-text">周边放映</div>
      <div class="module-title-more">
        <!-- <community-selector
          @onFinish="saveuserPoint"
          ref="refCommunitySelector"
        >
        </community-selector> -->
        <span @click="goPage('/ruralFilm/pointMap')"
          >{{ userPoint.name || '请选择放映点' }}
          <van-icon name="location" />
        </span>
        <!-- 跳转到放映点选择页面 -->
        <span
          style="color: #007aff; padding: 0 5px"
          @click="goPage('/ruralFilm/pointMap', { form: $route.path })"
          >更改</span
        >
      </div>
    </div>
    <div class="film-list" v-if="filmList.length">
      <div
        class="film-list-item"
        v-for="movie in filmList"
        :key="movie.id"
        @click="goPage(`/ruralFilm/nearbyCinema/filmDetail/${movie.id}`)"
      >
        <film-item :movie="movie" />
      </div>
      <div class="film-list-more">
        <div @click="goPage('/ruralFilm/nearbyCinema')">查看更多</div>
      </div>
    </div>
    <div v-else>
      <van-empty description="暂无数据" />
    </div>
    <!--    我要点播-->
    <div class="module-title">
      <div class="module-title-text">我要点播</div>
      <div class="module-title-more">
        <span @click="goPage('/onDemand', { t: '' })"
          >查看全部<van-icon name="arrow" size="16"
        /></span>
      </div>
    </div>
    <div>
      <van-grid gutter="10" style="width: 102.5%; padding-left: 0px">
        <van-grid-item
          v-for="item in onDemandTypeList"
          :key="item.text"
          :icon="item.icon"
          :text="item.text"
          @click="goPage('/onDemand', { t: item.text })"
        />
      </van-grid>
    </div>
    <!--    功能菜单-->
    <!-- <div class="ruralFilm-menu">
      <div class="ruralFilm-menu-item" @click="goPage('/onDemand', { t: 1 })">
        <div class="ruralFilm-menu-item-icon">
          <img
            :src="pageIcon.onDemand"
            alt=""
            style="width: 100%; height: 100%"
            srcset=""
          />
        </div>
        <div class="ruralFilm-menu-item-title">
          我要点播
        </div>
      </div>
    </div> -->

    <!--      <div class="ruralFilm-menu-item" @click="goPage('/joinGroup', { t: 0 })">-->
    <!--        <div class="ruralFilm-menu-item-icon">-->
    <!--          <img :src="pageIcon.group" alt="" srcset="" />-->
    <!--        </div>-->
    <!--        <div class="ruralFilm-menu-item-title">拼团点播</div>-->
    <!--      </div>-->
    <!-- <div class="ruralFilm-menu-item" @click="goPage('/onDemand', { t: 2 })">
        <div class="ruralFilm-menu-item-icon">
          <img :src="pageIcon.onDemandGroup" alt="" srcset="" />
        </div>
        <div class="ruralFilm-menu-item-title">集体点播</div>
      </div>
      <div class="ruralFilm-menu-item" @click="goPage('sessionPlan', { t: 0 })">
        <div class="ruralFilm-menu-item-icon">
          <img :src="pageIcon.notice" alt="" srcset="" />
        </div>
        <div class="ruralFilm-menu-item-title">放映公告</div>
      </div>
      <div
        class="ruralFilm-menu-item"
        @click="goPage('/sessionEvaluate', { t: 0 })"
      >
        <div class="ruralFilm-menu-item-icon">
          <img :src="pageIcon.evaluation" alt="" srcset="" />
        </div>
        <div class="ruralFilm-menu-item-title">放映评价</div>
      </div> -->
    <!--      {{typeof $store.state.userType}}-->
    <!-- <div
        class="ruralFilm-menu-item"
        v-if="false"
        @click="goPage('/projectionists', { t: 0 })"
      >
        <div class="ruralFilm-menu-item-icon">
          <img :src="pageIcon.projectionists" alt="" srcset="" />
        </div>
        <div class="ruralFilm-menu-item-title">放映员风采</div>
      </div>
    </div> -->
    <!-- <div class="more-btn">
      <a @click="HandlerMoreBtn">更多</a>
    </div> -->
    <!--    {{hotList}}-->
    <!-- <van-tabs v-model="activeName" color="#0069FF" animated sticky>
      <van-tab name="正在拼团">
        <template #title>正在拼团</template>
        <group-top10 v-if="showGroupList"></group-top10>
      </van-tab>
      <van-tab title="推荐影片" name="推荐影片">
        <template #title>推荐影片</template>
        <div v-for="(movie, index) in newList" :key="index">
          <movieCard
            :movie-img="movie.filmPicture"
            :movie-title="movie.name"
            :movie-score="movie.score"
            :movie-play-num="movie.playNum"
            :movie-type="movie.type"
            :movie-id="movie.id"
            :movie-length="movie.filmLength"
            :movie-year="movie.year"
            :movie-description="movie.description"
            @select="handleMovie"
          ></movieCard>
        </div>
      </van-tab>
    </van-tabs> -->
    <br />
    <br />
    <br />
    <tab-bar :active="1" />
  </div>
</template>

<script>
// import Header from '@/components/header'
import Swipe from '@/components/swipe'
import tabBar from '@/components/tabbar'
// import movieCard from '@/components/movieCard'
import { Toast } from 'vant'
import { api } from '@/api/index'
import common from '@/utils/common'
// import groupTop10 from '@/components/groupTop10'
import navBar from '@comp/navBar/index.vue'
import filmItem from './modules/filmItem.vue'

export default {
  name: 'ruralFilm',
  components: {
    Swipe,
    tabBar,
    // Header,
    filmItem,
    navBar,
    // movieCard,
    // groupTop10,
  },
  data() {
    return {
      activeName: '正在拼团',
      userType: '',
      bannerList: [],
      showGroupList: true,
      // hotList: [],
      newList: [],
      filmList: [],
      // 点播类型
      onDemandTypeList: [
        {
          icon: 'https://img.dev.zhijiangfilm.com/i/2024/10/08/67048c4d32713.png',
          text: '故事片',
        },
        {
          icon: 'https://img.dev.zhijiangfilm.com/i/2024/10/08/67048c4ba78b3.png',
          text: '戏曲片',
        },
        {
          icon: 'https://img.dev.zhijiangfilm.com/i/2024/10/08/67048c46d6823.png',
          text: '科教片',
        },
        // {
        //   icon: 'https://img.dev.zhijiangfilm.com/i/2024/10/08/67048c4aa6f5f.png',
        //   text: '喜剧片',
        // },
        {
          icon: 'https://img.dev.zhijiangfilm.com/i/2024/10/08/67048c4de0cf2.png',
          text: '纪录片',
        },
        {
          icon: 'https://img.dev.zhijiangfilm.com/i/2024/10/08/67048c4eebe17.png',
          text: '动画片',
        },
        {
          icon: 'https://img.dev.zhijiangfilm.com/i/2024/10/08/67048c480df70.png',
          text: '美术片',
        },
        {
          icon: 'https://img.dev.zhijiangfilm.com/i/2024/10/08/67048c48e7484.png',
          text: '其它',
        },
      ],
      themeList: [],
      userPoint: {},
    }
  },
  mounted() {
    this.userType = this.$store.state.userType.toString()
    // 如果用户没有选择放映点，则打开放映点选择器
    if (!this.$store.state.userPoint) {
      console.log(
        '打开放映点选择器',
        this.$store.state.userPoint,
        this.$refs.refCommunitySelector,
      )
      // 跳转到选择放映点页面
      this.$router.replace({
        path: '/ruralFilm/selectPoint',
        query: {
          form: this.$route.path,
        },
      })
    } else {
      // 已选择放映点
      this.userPoint = this.$store.state.userPoint
      this.getNearbyFilmList(30)
    }
    this.$nextTick(() => {
      // this.getTop10(0)
      // this.getTop10(5).then(() => {
      //   this.getTop10(4);
      // });
    })
    this.getBannerList()
    this.getThemeList()
  },
  methods: {
    // 获取专题列表
    getThemeList() {
      api
        .getThemeList({})
        .then()
        .then((res) => {
          console.log({ res })
          // this.themeList = res.filter((r) => r.status === 1)
          this.themeList = res
        })
    },
    tapBanner(item){
      this.$router.push({
        path: '/ruralFilm/topic/' + item.id,
      })
      console.log('DDD',item)
    },
    // 获取轮播图
    getBannerList() {
      api.getBanner({ bannerUse: 2 }).then((res) => {
        // 最多5张
        // this.bannerList = res.data.slice(0,5)
        this.bannerList = res
          .map((banner) => {
            return {
              img: banner.banner_ossUrl,
              url: banner.linkUrl,
            }
          })
          .slice(0, 5)
      })
    },
    // 获取周边放映影片
    getNearbyFilmList(days = 7) {
      api
        .getFilmAroundList({
          showPointId: this.userPoint.id,
          startDate: this.moment().format('YYYY-MM-DD'),
          endDate: this.moment().add(days, 'days').format('YYYY-MM-DD'),
        })
        .then((res) => {
          console.log({ res })
          const { code, result, message, success } = res
          // if (success) {
            this.filmList = result.map((item) => {
              return {
                id: item.id,
                name: item.name,
                score: item.score,
                img: item.filmPicture,
                type: item.type,
                length: item.filmLength,
                year: item.year,
                actor: item.director,
              }
            })
          // }
        })
    },
    handleMovie(movie) {
      // Toast("子组件点击了点播电影" + movieId);
      // console.log(movie);
      this.$router.push({
        path: '/filmDetail',
        query: {
          id: movie.movieId,
          playType: 1,
        },
      })
    },

    goPage(page, query = { t: 0 }) {
      console.log(page, query)
      if (query.t === 2) {
        if (common.hasPermission('/groupOnDemand', this.userType)) {
          this.$router.push({
            path: page,
            query: query,
          })
        } else {
          Toast('系统监测您不是集体点播代表，请联系当地宣传部处理')
        }
        // console.log(common.hasPermission("/groupOnDemand", this.userType));
      } else {
        console.log(this.activeName)
        this.$router.push({
          path: page,
          query: query,
        })
      }
    },
    // 获取推荐Top10影片
    getTop10(sort) {
      return this.$axios({
        apiUrl: '/film/mobile/list',
        url: 'mgop.wasu.zyt.film0mobile0list',
        method: 'GET',
        data: {
          sort: sort,
        },
      })
    },
    // 更新拼团组件的数据
    updateGroupList() {
      // 卸载
      this.showGroupList = false
      // 建议加上 nextTick 微任务
      // 否则在同一事件内同时将tableShow设置false和true有可能导致组件渲染失败
      this.$nextTick(function () {
        // 加载
        this.showGroupList = true
      })
    },
    HandlerMoreBtn() {
      if (this.activeName === '正在拼团') {
        this.$router.push({
          path: '/joinGroup',
        })
      } else {
        this.$router.push({
          path: '/onDemand',
          query: {
            t: 1,
          },
        })
      }
    },
    // 保存放映点
    saveuserPoint(point) {
      console.log({ point })
      if (point) {
        this.$store.dispatch('changeuserPoint', point)
        this.userPoint = point
      } else {
        Toast('请选择放映点')
        this.$refs.refCommunitySelector.showCommunitySelector()
      }
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .van-tab {
  max-width: 80px;
}

.module-title {
  font-size: 20px;
  //font-family:PingFangSC,"Microsoft YaHei", PingFangSC-Medium, PingFang SC, serif;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #363a44;
  line-height: 28px;
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &-text {
    width: 96px;
    height: 28px;
    padding-left: 8px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      #d3e3ff 100%
    );
    border-radius: 14px;
  }
  &-more {
    margin-right: 12px;
    color: #686b73;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    &>span{
      text-align: right;
      &:first-child{
        width: 50vw;
       overflow: hidden;
        //不允许换行
        white-space: nowrap;
        text-overflow: ellipsis;
        //max-width: 100px;
      }
    }
  }
}

.ruralFilm {
  width: 100%;
  position: relative;
  padding: 0 16px;
  box-sizing: border-box;
  z-index: 2;
  background-color: #f6f8fb;

  &-menu {
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    //border: 1px solid #f0f0f0;
    &-item {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-icon {
        width: 60px;
        height: 60px;
        //border:1px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        text-align: center;

        > img {
          width: 100%;
        }
      }

      &-title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC, serif;
        font-weight: 400;
        color: #363a44;
        line-height: 20px;
      }
    }
  }
}

.more-btn {
  display: inline-block;
  padding: 5px;
  font-size: 12px;
  background: #efeff0;
  border-radius: 80px;
  width: 30px;
  text-align: center;
  color: gray;
  float: right;
  z-index: 999;
  position: relative;
  /* margin-bottom: 10px; */
  transform: translateY(10px);

  > a {
    color: #b3b5b9;
  }
}
.film {
  &-list {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    padding-bottom: 5px;
    &-item {
      margin-right: 10px;
      width: 150px;
    }
    &-more {
      display: flex;
      justify-content: center;
      align-items: center;
      & > div {
        margin: 0 15px;
        width: 130px;
        border: @border-width-medium solid @color-primary;
        border-radius: @border-radius-base;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: @color-primary;
      }
    }
  }
}
</style>
